<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-adicionar
          v-if="$temPermissao('usuarios-insercao')"
          to="/usuarios/adicionar"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="$temPermissao('usuarios-listagem')"
      class="mt-4"
    >
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="usuarios"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarUsuarios"
          >
            <!-- <template v-slot:[`item.status`]="{ item }">
              {{ item.status ? 'Ativo' : 'Inativo' }}
            </template> -->
            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
              <table-btn-editar
                v-if="$temPermissao('usuarios-visualizacao')"
                :href="`/usuarios/${item.id}/editar`"
              />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import usuarios from '@/api/usuarios'

  export default {

    data () {
      return {
        carregando: false,
        carregandoDeletar: false,
        dialogDeletar: false,
        totalRegistros: 0,
        ativo: 'Ativo',
        inativo: 'Inativo',
        idExcluir: null,
        cabecalho: [
          { text: 'Nome', value: 'pessoa.nome' },
          { text: 'Usuário', value: 'nome_usuario' },
          { text: 'Status', value: 'status'},
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        usuarios: [],
        paginacao: {},
      }
    },

    methods: {
      async carregarUsuarios () {
        try {
          this.carregando = true

          const resposta = await usuarios.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.usuarios = resposta.data.usuarios
          this.usuarios.map ((item) => {
            console.log(item, 'this.usuarios.map')
            if (item.status === 1) {
              item.status = this.ativo
            } else {
              item.status = this.inativo
            }
          })
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
<style scoped>
  .verde {
    color: green
  }
  .vermelho {
    color: red
  }
</style>